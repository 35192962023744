import { gql } from '@apollo/client';
import { INVOICE_FRAGMENT } from '../../invoice';

export const GROUP_ADMIN_LIST_QUERY = gql`
  ${INVOICE_FRAGMENT}
  query adminBilling {
    groups {
      id
      customerNumber
      name
      isCourseSubtenant
      isCourseGroup
      isDemoGroup
      isTenant
      couresType
      allowMultipleMembers
      payOwnCost
      billing {
        id
        isPrivate
        companyName
        orgNo
        email
        reference
        firstName
        lastName
        address
        postalCode
        city
      }
      tenant {
        id
        name
      }
      invoices {
        ...InvoiceFragment
      }
      members {
        id
        role
        memberAt
        status
        addedBy {
          id
          name
        }
        user {
          id
          name
          email
        }
      }
      subscriptions {
        id
        hasActive
        features
        limits {
          users
          employees
          years
        }
        subscriptions(history: true) {
          id
          planId
          name
          start
          end
          isActive
          extra
          features
          price {
            interval
            renew
            users
            employees
            years
            discount
            subtotal
            totalNet
            totalVat
            total
            totalDiscount
            isPrivate
          }
        }
      }
      customerOf {
        name
        telephone
        email
        web
        vatNo
        orgNo
        bankGiro
        address
        postalCode
        city
      }
    }
  }
`;

export const UPDATE_GROUP_ADMIN_MUTATION = gql`
  mutation updateGroupAdmin($id: ID!, $input: GroupAdminInput!) {
    updateGroupAdmin(id: $id, input: $input) {
      id
      customerNumber
      isCourseSubtenant
      isCourseGroup
      isDemoGroup
      couresType
      allowMultipleMembers
      payOwnCost
    }
  }
`;

export const INQUIRIES_QUERY = gql`
  query inquiries {
    inquiries {
      id
      email
      reason
      company
      firstName
      lastName
      message
      date
    }
  }
`;

export const GET_DATA_VERSION = gql`
  query dataVersion($groupId: ID!) {
    dataVersion(groupId: $groupId)
  }
`;

export const UPDATE_DATA_VERSION = gql`
  mutation setDataVersion($groupId: ID!, $version: DataVersion) {
    setDataVersion(groupId: $groupId, version: $version)
  }
`;

export const GET_CAHCE_STATS = gql`
  query cacheStats($groupId: ID!) {
    cacheStats(groupId: $groupId)
  }
`;

export const LIST_CONFIG = gql`
  query listConfig($groupId: ID!) {
    listConfig(groupId: $groupId)
  }
`;

export const UPDATE_CAHCE_STATS = gql`
  mutation setCacheStats($groupId: ID!, $value: Boolean) {
    setCacheStats(groupId: $groupId, value: $value)
  }
`;

export const UPDATE_SETTING_BOOLEAN = gql`
  mutation setConfigBoolean($groupId: ID!, $type: String!, $value: Boolean!) {
    setConfigBoolean(groupId: $groupId, type: $type, value: $value)
  }
`;

export const CLEAR_STATS_CACHE = gql`
  mutation clearStatsCache($groupId: ID!) {
    clearStatsCache(groupId: $groupId) {
      success
      message
    }
  }
`;

export const DELETE_INQUIRY_MUTATION = gql`
  mutation deleteInquiry($id: String!) {
    deleteInquiry(id: $id) {
      id
    }
  }
`;

export const RESEND_VERIFY_EMAIL = gql`
  mutation resendVerifyEmail($id: String!) {
    resendVerifyEmail(id: $id) {
      success
      message
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation deleteGroup($id: ID!) {
    deleteGroup(id: $id) {
      id
    }
  }
`;

export const RAW_SUBSCRIPTIONS_QUERY = gql`
  query rawSubscriptions($groupId: ID!) {
    rawSubscriptions(groupId: $groupId) {
      id
      start
      end
      type {
        type
        version
        billingOccurance
      }
    }
  }
`;

export const RAW_TYPE_OPTIONS_QUERY = gql`
  query rawTypes {
    rawTypes {
      type
      name
      enabled
      course
      extra
      versions {
        version
        enabled
        monthly
        annual
        custom
      }
    }
  }
`;

const RAW_RESPONSE_FRAGMENT = gql`
  fragment RawResponseFragment on GroupSubscriptionRawResponse {
    raw {
      id
      start
      end
      type {
        type
        version
        billingOccurance
      }
    }
    subscription {
      id
      planId
      name
      start
      end
      isActive
      extra
      features
      price {
        interval
        renew
        users
        employees
        years
        discount
        subtotal
        totalNet
        totalVat
        total
        totalDiscount
        isPrivate
      }
    }
  }
`;

export const ADD_RAW_SUBSCRIPTION = gql`
  ${RAW_RESPONSE_FRAGMENT}
  mutation addRawSubscription(
    $groupId: ID!
    $subscription: GroupSubscriptionRawInput!
  ) {
    addRawSubscription(groupId: $groupId, subscription: $subscription) {
      ...RawResponseFragment
    }
  }
`;

export const UPDATE_RAW_SUBSCRIPTION = gql`
  ${RAW_RESPONSE_FRAGMENT}
  mutation updateRawSubscription(
    $groupId: ID!
    $id: ID!
    $subscription: GroupSubscriptionRawInput!
  ) {
    updateRawSubscription(
      groupId: $groupId
      id: $id
      subscription: $subscription
    ) {
      ...RawResponseFragment
    }
  }
`;

export const DELETE_RAW_SUBSCRIPTION = gql`
  mutation removeRawSubscription($groupId: ID!, $id: ID!) {
    removeRawSubscription(groupId: $groupId, id: $id) {
      success
      message
    }
  }
`;

export const STANDARD_MAPPING_QUERY = gql`
  query standardMapping {
    standardMapping {
      sourceType
      mapping {
        map
        id
        paycode
        description
      }
    }
  }
`;
